.calender-content{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
}

.calender-content h3{
    margin: 0px;
    padding: 0px;
}

.calender-info{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.calender-image{
    width: 200px;
}

.calender-text{
    padding: -0px 0px 0px 20px;    
    width: auto;
}

.calender-text p{
    margin-top:-5px;        
}

.grid-item{    
    min-width: 200px;
}

@media screen and (max-width: 768px){
    .calender-content{
        flex-direction: row;
    }
    .calender-info{
        flex-direction: column;
    }
    .calender-text{
        padding-left: 0px;  
        padding-top: 10px;
    }
}