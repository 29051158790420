.footer{
    margin:0;
    height:60px;
    background-color: var(--secondary-purple-color);
    padding:30px 10px 15px 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.footer a{
    text-decoration: none;
    color: black;
}

.footer p{
    margin-top:5px;
}