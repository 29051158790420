body{
    background-image: url(./images/bakgrund.jpg);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    line-height: 1.7em;
}

/* [ CONTENT CONTAINERS ] */
.main-content{   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding:100px 0px 50px 0px;    
    flex-wrap: wrap;
}

.bottom{
    padding-top: 50px;
}

.image-container{
    border:1px solid yellow;
    height: auto;
    max-width: 30%;
}

.image{
    max-width:100%;
    max-height:100%;
    min-width: 200px;
}

.text-container{
    max-width: 65%;
}

.column-container{
    padding: 10px 30px 0px 30px;
    margin: 0px 30px 0px 30px;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    max-width: 70%;
}

.grid-item{
    padding:50px;  
    margin: 15px;
}

.center-content{
    max-width: 70%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;  
}

.color-line-content{
    background-color: #C0B1DB;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding:15px;
    flex-wrap: wrap;
    box-shadow: 1px 1px 4px 0 #888888;
}

.color-line-content .grid-item{
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-self: center;
    align-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.card-padding{
    padding: 30px;
}

ul{
    margin-top:0px;
    margin-bottom: 0px;
}

/* [ BUTTONS ] */
.round-button{
    border-radius: 30px;
    background-color: black;
    color: black;
    border: none;
    width: auto;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0px 40px 0px 40px;
}
.round-button:hover{
    background-color: whitesmoke;
}

/* [ HEADERS ] */
h3{
    font-family: 'Permanent Marker', cursive;
}
.header,
.main-content h3{
    padding: 20px 0px 10px 0px;
    margin:0;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 24px;
    color: var(--secondary-purple-color);
}
.date{
    margin-top:8px;    
    text-transform: uppercase;
    color:grey;
}

/* [ TEXT ] */
.small-text{
    font-size: 12px;
}
.bold-text{
    font-weight: bold;
}
.italic-text{
    font-style: italic;
}
.grid-item a {
    text-decoration: none;
    color: black;
    font-style: italic;
}
.grid-item a:hover{
    text-decoration: underline;
}
.right{
    display: flex;
    justify-content: flex-end;
}

.main-content a:hover,
.main-content a{
    color: var(--secondary-purple-color);
    font-style: normal;
}

/* [ ON SMALL SCREENS ] */
@media screen and (max-width: 768px){
    .main-content{        
        padding:75px 0px 20px 0px;  
        flex-direction: column;
        flex-wrap: wrap;
    } 
    .main-content h3{
        font-size: 20px;
    } 
    .center-content{
        max-width: 95%;
    }  
    .grid-item{
        padding:20px;
    }
    .card-padding{
        padding:8px;
    }   
    .bottom{
        padding-top: 20px;
    }    
  }

/* [ ON EXTRA BIG SCREENS ] */
@media screen and (min-width: 2000px){
    .center-content{
        max-width: 55%;
    }
  }