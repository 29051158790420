:root {
    --primary-yellow-color: #FFE393;
    --primary-orange-color: #FFB599;
    --primary-purple-color: #C0B1DB;
    --primary-grey-color: #FCFBF7;
    --secondary-purple-color: #836FA9;
    --secondary-yellow-color: #F9D981;
    --secondary-grey-color: #333333;
  }
  
  