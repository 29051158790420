.form-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-left:9px;
}

/* [ ON SMALL SCREENS ] */
@media screen and (max-width: 768px){
    .form-bottom{
        flex-direction: column;
        justify-content: center;
    }
  }