.homepage-content{
    height:100vh;
    background-image: url("images/hemsida_start.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-content: space-around;
}

h1{
    font-size: 6vw;
    color: var(--secondary-yellow-color);
    text-shadow: 2px 2px 4px var(--secondary-grey-color);
    text-align: center;
    line-height: normal;
    font-family: 'Permanent Marker', cursive;
    margin: 35px 0px 0px 0px;
    padding:0px 5px 0px 5px;
}

.second-header-content{
    max-width: 50%;
    text-align: center;
    background-color: var(--primary-purple-color);
    border-radius: 40px;
    padding:30px;
    height: auto;
    opacity: 0.9;
}

h2{
    color:black;
    font-size: 3vw;
    line-height: normal;
    text-transform: none;
    font-weight: bold;
    font-family: 'Permanent Marker', cursive;
    margin-top:0px;
    margin-bottom: 0px;
}

.button-navlink{
    text-decoration: none;
}

/* [ON SMALL SCREENS] */
/* @media screen and (max-width: 768px) , screen and (min-width: 341px){*/
    @media (min-width:341px) and (max-width:768px){
    .homepage-content{
        background-size: cover;
        background-position: -100px;
    }
    h1{        
        font-size: 9vw;
        margin-top:10px;
    }  
    h2{
        font-size: 6vw;
    } 
  } 

  /* [ON EXTRA SMALL SCREENS] */
@media screen and (max-width: 340px){
    .homepage-content{
        background-size: cover;
        background-position: -100px;
    }
    h1{        
        font-size: 11vw;
        margin-top: 50px;
    }  
    h2{
        font-size: 5vw;
    } 
    .second-header-content{
        padding:15px;
        border-radius: 30px;
    }
  }