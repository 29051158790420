/* [ MENU ] */
.menu-item{
  color:black;
  text-decoration: none;
}

.header {
  background-color: var(--secondary-yellow-color);
  box-shadow: 1px 1px 4px 0 #888888;
  position: fixed;
  width: 100%;
  z-index: 3;
  padding-top: 12px;
  border-bottom: 5px solid var(--primary-purple-color);
}

.header ul {
  margin: 0;
  padding: 0px 0px 0px 0px;
  list-style: none;
  overflow: hidden;
}

.header li  {
  display: block;
  padding: 10px 10px 10px 30px;
  text-decoration: none;
  color: black;
}

.header li:hover,
.header .menu-btn:hover {
  background-color: var(--primary-purple-color); 
}

.header .logo {
  display: block;
  float: left;
  font-size: 1em;
  padding: 0px 30px;
  text-decoration: none;
  font-family: 'Permanent Marker', cursive;
  color: var(--secondary-grey-color)
}

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
  font-size: 16px;
}

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 15px 15px;
  position: relative;
  user-select: none;  
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 20px;  
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* [ MENU BUTTON ] */
.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 285px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* [ ON BIG SCREENS ] */
@media (min-width: 910px) {
  .header li {
    float: left;
    padding: 8px 8px 2px 8px;    
    margin:-8px 10px 0px 10px;      
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;    
  }
  .header .menu-icon {
    display: none;
  }   
  .header li:hover,
  .header .menu-btn:hover {  
  border-bottom: 2px solid var(--secondary-grey-color);
  background-color: var(--secondary-yellow-color);
  padding-bottom: 0px;
  }
  .chosen{
    border-bottom: 2px solid var(--secondary-grey-color);
    padding-bottom: 3px;    
  }
  .header ul {
    margin: 0;
    padding: 0px 20px 0px 0px;
    list-style: none;
    overflow: hidden;
  }
}


  /* [ON EXTRA SMALL SCREENS] */
  @media screen and (max-width: 340px){
    .header .logo {
      font-size: 6vw;
    }
  }